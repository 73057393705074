import React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import {CONTACT_US, HOME_PAGE} from "../services/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  position: relative;
  @media screen and (max-width: 899px) {
    min-height: 100vh;
    background-size: 400%;
    background-position: right 10% bottom 85%;
  }
  @media screen and (min-width: 900px) {
    min-height: 100vh;
    background-size: 120%;
    background-position: right 85% bottom 95%;
  }
`;

export default function Custom404() {
  const { data: session, status } = useSession();

  return (
    <Container
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)), url("/assets/Adobe-hands-raised.jpg")`,
      }}
    >
      <TitleBox>
        <Title>404</Title>
        <Explanation>Sorry, the page you were looking for could not be found.</Explanation>
      </TitleBox>
      <InstructionBox>
        <Instructions>
          You can return to our{' '}
          <InstructionLink>
            <Link href={session ? HOME_PAGE : '/'}>home page</Link>
          </InstructionLink>{' '}
          or{' '}
          <InstructionLink>
            <Link href={CONTACT_US}>contact us</Link>
          </InstructionLink>{' '}
          to report this problem.
        </Instructions>
      </InstructionBox>
    </Container>
  );
}

const TitleBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.palette.primary.light,
    width: '45%',
    maxHeight: '150px',
  },
  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.primary.light,
    width: '75%',
    maxHeight: '150px',
  },
}));

const Title = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  fontWeight: 300,
  [theme.breakpoints.up('md')]: {
    fontSize: '3.40rem',
    lineHeight: '3.65rem',
    margin: '15px 30px 5px 30px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.50rem',
    lineHeight: '2.65rem',
    margin: '15px 30px 5px 30px',
  },
}));

const Explanation = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '1.20rem',
  lineHeight: '32px',
  marginBottom: 10,
  margin: '5px 10px 20px 10px',
}));

const InstructionBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '-35px 0 0 0',
  [theme.breakpoints.up('md')]: {
    width: '52%',
    minHeight: '180px',
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
    minHeight: '160px',
  },
}));

const Instructions = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 300,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    margin: '75px 10px 25px 10px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.00rem',
    lineHeight: '1.15rem',
    margin: '80px 10px 25px 10px',
  },
}));

const InstructionLink = styled('span')(({ theme }) => ({
  color: '#008B8B',
  fontWeight: 500,
}));
